<template>
  <div class="home">
    <!-- eslint-disable -->
    <div class="legal-holder">
      <h1>Terms and Conditions</h1>
      <p>Use of Tuumik is governed by the terms and conditions stipulated in the following documents:</p>
      <p><router-link class="flink" to="/legal/end-user-license-agreement">End User License Agreement</router-link></p>
      <p><router-link class="flink" to="/legal/terms-of-service">Terms of Service</router-link></p>
      <p><router-link class="flink" to="/legal/privacy-policy">Privacy Policy</router-link></p>
    </div>
    <!-- eslint-enable -->
  </div>
</template>

<script>
export default {
  name: 'TermsOfService',
  mounted() {
    document.body.scrollIntoView({ behavior: 'instant' });
  },
};
</script>

<style scoped>
.home {
  padding: 3em 0 1em 0;
}

.legal-holder {
  margin: 6em 0;
  padding: 1em;
  text-align: justify;
  background-color: #ffffff;
  color: #000000;
  border-radius: 0.3em;
  border: 1px solid #cecece;
  box-shadow: 0 0 0.9em 0 rgba(0, 0, 0, 0.07);
}

.legal-holder h1 {
  font-size: 2.1em;
  text-align: center;
  margin: 1em 0 2em 0;
}

.legal-holder h2 {
  font-weight: 600;
  font-size: 1.2em;
  margin: 1em 0;
}

@media only screen and (min-width: 768px) {
  .legal-holder {
    margin: 6em auto;
    padding: 2em 3em;
    max-width: 50em;
  }
}
</style>
