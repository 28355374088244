<template>
  <div class="footer-bar">
    <div class="logo-footer"></div>
    <div class="footer-box">
      <div class="box-name">CONTACT</div>
      <div class="box-txt">Tuumik Systems</div>
      <div class="box-txt">info@tuumik.com</div>
      <div class="box-txt">Tallinn, Estonia</div>
    </div>
    <div class="footer-box">
      <div class="box-name">SUPPORT</div>
      <div class="box-txt">support@tuumik.com</div>
    </div>
    <div class="footer-box">
      <div class="box-name">LEGAL</div>
      <div class="link-item">
        <router-link class="flink" to="/legal">Terms and Conditions</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterBar',
};
</script>

<style scoped>
.footer-bar {
  background-color: #2f2f2f;
  color: #ffffff;
  padding: 3em 1em 4em 1em;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.logo-footer {
  width: 2em;
  height: 2em;
  margin: 1em 9em 2em 0;
  background-image: url('~@/assets/logo-green.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
}

.footer-box {
  width: 11em;
  margin: 1em;
  text-align: left;
}

.box-name {
  color: #7f7f7f;
  margin: 0 0 1em 0;
}

.box-txt {
  margin: 0 0 1em 0;
}

.link-item {
  margin: 0 0 1em 0;
}

.flink,
.flink:hover,
.flink:visited,
.flink:active {
  text-decoration: none;
  color: #ffffff;
}

@media only screen and (min-width: 768px) {
  .footer-bar {
    height: 27em;
  }

  .logo-footer {
    margin: 1em 3em 0 0;
  }
}
</style>
